// import React from 'react';
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbarbig.css";
import logoImage from "../../assets/logo.png";
import githubLogo from "../../assets/github.png";
import linkedinLogo from "../../assets/linkedin.png";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

const NavbarBig = () => {
  const githubLink = "https://github.com/VanshDhir";
  const linkedinLink = "https://linkedin.com/in/vansh-d-27ba01219";

  return (
    <div className="navbar-big">
      <div className="navbar-left">
        <img src={logoImage} alt="Your Logo" className="logo" />
        <a href={githubLink} target="_blank" rel="noopener noreferrer">
          <img src={githubLogo} alt="GitHub" className="logo" />
        </a>
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
          <img src={linkedinLogo} alt="LinkedIn" className="logo" />
        </a>
      </div>
      <div className="navbar-middle">
        <ul className="nav-links">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/Intro">About</NavLink>
          </li>
          <li>
            <NavLink to="/experience">Experience</NavLink>
          </li>
          <li>
            <NavLink to="/skills">Skills</NavLink>
          </li>
          <li>
            <NavLink to="/projects">Projects</NavLink>
          </li>
          <li>
            <a
              href="https://drive.google.com/file/d/1L9NMo2lmsjNXKcHyVlPCkFnHi_KJF3VG/view?usp=sharing"
              target="_blank">
              Resume
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
        <NavLink to="/Contact" className="contact-button">
          Contact Me
        </NavLink>
      </div>
    </div>
  );
};

export default NavbarBig;
