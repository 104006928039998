import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './intro.css';
import database from '../../assets/icons/database.png';
import github from '../../assets/icons/github.png';
import terminal from '../../assets/icons/terminal.png';
import python from '../../assets/icons/python.png';


const About = () => {

  return (
    <div className="about-page">
      <div className="about-left">
        <div className="basic-info">
          <h1 className="about-heading">About me</h1>
          <h2>I love Technology, Experimentation and Problem Solving</h2>
          <p className="info-paragraph">
            I'm a 22-year-old college student deeply passionate about technology, creativity, teamwork, and software engineering, particularly in UI/UX design. I closely follow the ever-changing landscapes of technology, blockchain, economics, and research. I thrive on challenges, always seeking solutions with an open mindset. To broaden my skill set, I'm currently focused on learning more about back-end development. This allows me to explore software architecture, database management, and core functionalities that drive digital innovation. I'm committed to staying at the forefront of technology, learning, and contributing to innovation, eager to make a meaningful impact in this dynamic environment.
          </p>
        </div>
      </div>
      <div className="about-right">
        <div className="rotating-circle">
          <img src={database} alt="Icon 1" className="icon icon1" />
          <img src={github} alt="Icon 2" className="icon icon2" />
          <img src={terminal} alt="Icon 3" className="icon icon3" />
          <img src={python} alt="Icon 4" className="icon icon4" />
        </div>
      </div>
    </div>
  );
};

export default About;