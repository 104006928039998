// skills.js

import React from 'react';
import './skills.css';
import aws from '../../assets/icons/amazon-web-services.png'
import arduino from '../../assets/icons/arduino.png'
import css3 from '../../assets/icons/css3.png'
import git from '../../assets/icons/git.png'
import github from '../../assets/icons/github.png'
import gcp from '../../assets/icons/google-cloud-platform.png'
import heroku from '../../assets/icons/heroku.png'
import html5 from '../../assets/icons/html5.png'
import js from '../../assets/icons/javascript.png'
import linux from '../../assets/icons/linux.png'
import mongodb from '../../assets/icons/mongodb.png'
import mysql from '../../assets/icons/mysql.png'
import nodejs from '../../assets/icons/nodejs.png'
import python from '../../assets/icons/python.png'
import react from '../../assets/icons/react.png'
import sql from '../../assets/icons/sql.png'
import terminal from '../../assets/icons/terminal.png'
import vscode from '../../assets/icons/vscode.png'


const Skills = () => {
  return (
    <div className="skills-container">
      <h1 className="main-heading">Technologies I Know</h1>
      <h2 className="sub-heading">Technologies that I have hands-on experience with.</h2>
      <div className="photo-container">
        <div className="row">
          <div className="col-3">
            <img src={aws} alt="aws" />
          </div>
          <div className="col-3">
            <img src={arduino} alt="arduino" />
          </div>
          <div className="col-3">
            <img src={css3} alt="css" />
          </div>
          <div className="col-3">
            <img src={git} alt="git" />
          </div>
          <div className="col-3">
            <img src={html5} alt="html5" />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img src={gcp} alt="gcp" />
          </div>
          <div className="col-3">
            <img src={heroku} alt="heroku" />
          </div>
          <div className="col-3">
            <img src={github} alt="github" />
          </div>
          <div className="col-3">
            <img src={js} alt="javascript" />
          </div>
          <div className="col-3">
            <img src={linux} alt="linux" />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img src={mongodb} alt="mongodb" />
          </div>
          <div className="col-3">
            <img src={mysql} alt="mysql" />
          </div>
          <div className="col-3">
            <img src={nodejs} alt="nodejs" />
          </div>
          <div className="col-3">
            <img src={python} alt="python" />
          </div>
          <div className="col-3">
            <img src={react} alt="react" />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img src={sql} alt="sql" />
          </div>
          <div className="col-3">
            <img src={terminal} alt="terminal" />
          </div>
          <div className="col-3">
            <img src={vscode} alt="vscode" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
