import React from 'react';
import './experience.css';

const Experiences = () => {
  return (
    <div className="experiences-container">
      <h2>Experiences</h2>
      <div className="experience-box">
        <h3>Chief Technical Officer</h3>
        <p>Mittvaanee</p>
        <p>September 2023 - December 2023</p>
      </div>
      <div className="experience-box">
        <h3>Backend Development Intern</h3>
        <p>Aara Associates</p>
        <p>Aug 2021 - Sept 2021</p>
      </div>
      <div className="experience-box">
        <h3>Machine Learning Engineer Intern</h3>
        <p>Brain O Vision Solutions Pvt Ltd</p>
        <p>June 2022 - July 2022</p>
      </div>

    </div>

  );
};

export default Experiences;
