import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './projects.css';

const Projects = () => {
  return (
    <div className="projects-container">
      <h1 className="heading">Projects</h1>
      <div className="projects">
        <div className="project-row">
          <div className="project-box">
            <h3>Amazon Clone</h3>
            <p>Amazon clone made using HTML and CSS</p>
            <a href="https://github.com/Vanshdhir/Amazon-clone" target='_blank' className="project-link">View Project</a>
          </div>
          <div className="project-box">
            <h3>Mobile Price Classifier</h3>
            <p>Mobile price classifier made using Python, Pandas, Matplotlib, and Keras</p>
            <a href="https://github.com/Vanshdhir/mobilepriceclass" target='blank' className="project-link">View Project</a>
          </div>
        </div>
        <div className="project-row">
          <div className="project-box">
            <h3>Leaf Disease Detection</h3>
            <p>Identify leaf disease by uploading an image of the leaf</p>
            <a href="https://github.com/Vanshdhir/leafdiseasedetection" target='_blank' className="project-link">View Project</a>
          </div>
          <div className="project-box">
            <h3>File Fusion</h3>
            <p>File sharing app based on Node.js</p>
            <a href="https://github.com/Vanshdhir/File-fushion" target='_blank' className="project-link">View Project</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;