import React from 'react';
import NavbarBig from './navbarbig'; 
import NavbarSmall from './navbarsmall'; 
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargeScreen: window.innerWidth > 1000,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      isLargeScreen: window.innerWidth > 1000,
    });
  };

  render() {
    const { isLargeScreen } = this.state;

    return (
      <div>
        {isLargeScreen ? <NavbarBig /> : <NavbarSmall />}
      </div>
    );
  }
}

export default Navbar;
