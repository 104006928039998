import React, { useRef, useState } from 'react';
import './contact.css';
import emailjs, { sendForm } from '@emailjs/browser';


const Contact = () => {
  const form = useRef();
  const [showThankYou, setShowThankYou] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setShowThankYou(true);

    emailjs.sendForm('service_wi0xm5o', 'template_ozyso4u', form.current, 'yEBAP1a01ZJB49YUd')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    form.current.reset();
  };


  return (
    <div className="contact-container">
      <h1 class="unselectable">Contact Me</h1>
      {showThankYou ? ( // Display the thank-you message if showThankYou is true
        <div className="thank-you-message">
          <p>Thank you!</p>
          <p>We will get in touch with you.</p>
        </div>
      ) : (


        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name"></label>
            <input type="text" className="form-control" id="name" placeholder="Name" name="from_name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="E-mail"
              name="from_email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message"></label>
            <textarea
              className="form-control"
              id="message"
              placeholder="Your message"
              name="message"
              required
            ></textarea>

          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      )}
    </div >
  );
};

export default Contact;
