import React, { useState, useRef, useEffect } from "react";
import "./navbarsmall.css";
import logo from "../../assets/logo.png";
import githubLogo from "../../assets/github.png";
import linkedinLogo from "../../assets/linkedin.png";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

function NavbarSmall() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className="navbarsmall">
      <div className="left-half">
        <img src={logo} alt="Your Logo" />
        <a
          href="https://github.com/VanshDhir"
          target="_blank"
          rel="noopener noreferrer">
          <img src={githubLogo} alt="GitHub" className="social-icon" />
        </a>
        <a
          href="https://linkedin.com/in/vansh-d-27ba01219"
          target="_blank"
          rel="noopener noreferrer">
          <img src={linkedinLogo} alt="LinkedIn" className="social-icon" />
        </a>
      </div>
      <div className="right-half">
        <div className={`menu ${menuOpen ? "open" : ""}`} ref={menuRef}>
          <NavLink to="/" onClick={() => setMenuOpen(false)}>
            Home
          </NavLink>
          <NavLink to="/Intro" onClick={() => setMenuOpen(false)}>
            About
          </NavLink>
          <NavLink to="/experience" onClick={() => setMenuOpen(false)}>
            Experience
          </NavLink>
          <NavLink to="/skills" onClick={() => setMenuOpen(false)}>
            Skills
          </NavLink>
          <NavLink to="/projects" onClick={() => setMenuOpen(false)}>
            Projects
          </NavLink>
          <a
            href="https://drive.google.com/file/d/1L9NMo2lmsjNXKcHyVlPCkFnHi_KJF3VG/view?usp=sharing"
            target="_blank"
            onClick={() => setMenuOpen(false)}
            rel="noopener noreferrer">
            Resume
          </a>
          <NavLink to="/Contact" onClick={() => setMenuOpen(false)}>
            Contact Me
          </NavLink>
        </div>
        <div className="hamburger-icon" onClick={toggleMenu}>
          <i className="menu-icon">☰</i>
        </div>
      </div>
    </div>
  );
}

export default NavbarSmall;
