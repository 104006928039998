import React, { Component } from "react";
import Navbar from "./components/navbar/navbar";
import Home from "./components/Home/Home";
import Particles from "./components/background/background";
import Intro from "./components/Intro/intro"
import Experience from "./components/Experience/experience"
import Skills from './components/Skills/skills'
import Projects from './components/Projects/projects'
import Contact from './components/Contact/contact'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css"

function App() {
  return (
    <div className="App">
      <Particles />

      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Intro" element={<Intro />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/Skills" element={<Skills />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}


export default App
