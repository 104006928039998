import React from 'react';
import Typewriter from 'typewriter-effect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import myImage from '../../assets/dev.png'


const Home = () => {
  return (
    <div className="home">
      <div className="left-section">
        <h1 className="name">Hi, My name is Vansh</h1>
        <h2 className="passionate">And I am a passionate</h2>
        <div className="typewriter">
          <Typewriter
            options={{
              strings: ['Web Developer', 'Video Editor', 'Web Designer'],
              autoStart: true,
              loop: true,
              delay: 40,
              deleteSpeed: 40,
            }}
          />
        </div>
      </div>
      <div className="right-section">
        <div className="image-container">
          <img
            src={myImage}
            alt="Your Image"
            className="rounded-circle image"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;